import {
	COLOR_BLUE_HEX,
	COLOR_GOLD_HEX,
	COLOR_MAGENTA_HEX,
	COLOR_ORANGE_HEX,
	COLOR_PURPLE_HEX,
	COLOR_SLATE_BLUE_HEX,
	COLOR_TEAL_HEX
} from './ColorsHex';

let map = new Map();

map.set(COLOR_TEAL_HEX, '#00826A1A');
map.set(COLOR_ORANGE_HEX, '#CC4D081A');
map.set(COLOR_MAGENTA_HEX, '#C41D7F1A');
map.set(COLOR_PURPLE_HEX, '#5A26AE1A');
map.set(COLOR_BLUE_HEX, '#096DD91A');
map.set(COLOR_GOLD_HEX, '#CD9B461A');
map.set(COLOR_SLATE_BLUE_HEX, '#4654721A');

export default map;
