import './CollapsableLexicon.less';
import { AlignLeftOutlined, DownCircleOutlined, FileTextOutlined, PicLeftOutlined, StarFilled, UpCircleOutlined } from '@ant-design/icons';
import { Construct, ConstructWithExcerpts } from '../../interfaces/Construct';
import React, {useState} from 'react';
import { Tabs, Tag, Tooltip, Typography } from 'antd';
import { Excerpt } from '../../interfaces/Excerpt';
import {TextAnnotator} from 'react-text-annotate';
import { Transcript } from '../../interfaces/Transcript';

interface ExcerptWithConstruct extends Excerpt {
	construct: Construct;
}

interface ParagraphDictionary {
	[paragraphId: number]: { text: string } | undefined;
}

interface CollapsableLexiconProps {
	label: string;
	color: string;
	constructsWithExcerpts: ConstructWithExcerpts[];
	setAnnotateSelected: (annotateSelected: null | ExcerptWithConstruct) => void;
	setParagraphSelected: (paragraphId: number) => void;
	paragraphDictionary: ParagraphDictionary;
	setAnnotatorCallback: (annotator: { call: () => void }) => void;
	refreshLexicons: () => void;
	transcripts: Transcript[];
}

const CollapsableLexicon: React.FC<CollapsableLexiconProps> = ({ label, color, constructsWithExcerpts, setAnnotateSelected, setParagraphSelected, paragraphDictionary, setAnnotatorCallback, refreshLexicons, transcripts = [] }) => {
	const [showBody, setShowBody] = useState(false);
	const { Text } = Typography;
	const { TabPane } = Tabs;

	const toggleShowBody = () => {
		setShowBody(!showBody);
	};

	return (
		<div className='collapsable-lexicon'>
			<div className='collapsable-header' style={{ borderColor: color }} onClick={toggleShowBody}>
				<Text className='text'>{label}</Text>
				{constructsWithExcerpts && !!constructsWithExcerpts.length && (<div className='arrow-toggle'>
					{showBody ? <UpCircleOutlined /> : <DownCircleOutlined />}
				</div>)}
			</div>

			{ showBody && constructsWithExcerpts && constructsWithExcerpts.map((constructWithExcerpts, i) => {
				const numberOfLexicons = constructWithExcerpts.excerpts.length;
				const numberOfTranscripts = constructWithExcerpts.excerpts.filter((excerpt, index, array) => array.findIndex(item => item.paragraph.transcript.id === excerpt.paragraph.transcript.id) === index).length;

				return (
					<div key={i} className='collapsable-container'>
						<div className='title'>
							<Tag color='#E6FFFB' className='tag' >{constructWithExcerpts.name}</Tag>
							<span>{`Lexicons: ${numberOfLexicons} - Transcripts: ${numberOfTranscripts}`}</span>
						</div>
						{
							constructWithExcerpts.excerpts && constructWithExcerpts.excerpts.map((excerpt, i) =>{
								const sentenceIndex = (paragraphDictionary[excerpt.paragraph.id]?.text || '').indexOf(excerpt.sentence);

								const excerptWithConstruct: ExcerptWithConstruct = {
									...excerpt,
									construct: {
										id: constructWithExcerpts.id,
										description: constructWithExcerpts.description,
										name: constructWithExcerpts.name,
										type: {
											id: constructWithExcerpts.type,
											value: label,
											color: color,
										},
									},
								};

								const onClickExcerpt = () => {
									setAnnotateSelected(excerptWithConstruct);
									setParagraphSelected(excerpt.paragraph.id);
									setAnnotatorCallback({ call: refreshLexicons });
								};

								const title = `${transcripts.length ? transcripts.find(transcript => transcript.key === excerpt.paragraph.transcript.id)?.name + ' - ' : ''}` + excerpt.text;

								return (
									<div key={i} className='excerpt-container'>
										<div className='loyalist-container'>
											<StarFilled className='star-icon' />
											<div className='loyalist-text'>{title}</div>
										</div>

										<Tabs className='transcripts-tabs' type='card'>
											<TabPane key='1' tab={
												<span>
													<Tooltip title='Excerpt'>
														<PicLeftOutlined className='tabs-icons'/>
													</Tooltip>
												</span>}>
												<div className='lexicon-transcript-container' >
													<TextAnnotator
														className='text-annotator'
														content={excerpt.text}
														value={[{ start: 0, end: excerpt.text.length, color: '#E6FFFB' }]}
														onChange={onClickExcerpt}
													/>
												</div>
											</TabPane>
											<TabPane key='2' tab={
												<span>
													<Tooltip title='Sentence'>
														<AlignLeftOutlined className='tabs-icons'/>
													</Tooltip>
												</span>}>
												<div className='lexicon-transcript-container'>
													<TextAnnotator
														className='text-annotator'
														content={excerpt.sentence}
														value={[{ start: excerpt.start - sentenceIndex, end: excerpt.end - sentenceIndex, color: '#E6FFFB' }]}
														onChange={onClickExcerpt}
													/>
												</div>
											</TabPane>
											<TabPane key='3' tab={
												<span>
													<Tooltip title='Paragraph'>
														<FileTextOutlined className='tabs-icons'/>
													</Tooltip>
												</span>}>
												<div className='lexicon-transcript-container'>
													<TextAnnotator
														className='text-annotator'
														content={paragraphDictionary[excerpt.paragraph.id]?.text || ''}
														value={[{ start: excerpt.start, end: excerpt.end, color: '#E6FFFB' }]}
														onChange={onClickExcerpt}
													/>
												</div>
											</TabPane>
										</Tabs>
									</div>
								);
							})
						}
					</div>
				);
			})}
		</div>
	);
};

export default CollapsableLexicon;
