import G6, { Graph, LayoutConfig, ModeType } from '@antv/g6';
import React, { useEffect, useRef, useState } from 'react';
import { Map } from '../../interfaces/Map';
import MapColorsAnnotator from '../../utils/MapColorsForAnnotator';

interface UseConfigMapReturn {
	currentGraph: Graph
}

const configMapLayout = (useForceLayout: boolean) => {

	if (useForceLayout) {
		return {
			type: 'force',
			nodeSize: 150,
			preventOverlap: true,
		};
	}

	return undefined;

};

const minimap = new G6.Minimap({
	container: 'minimap-container',
	size: [160, 70],
});

const toolbar = new G6.ToolBar();

const getDefaultValues = (allowLinkCreation:boolean): ModeType[] => {
	const defaultValues: ModeType[] = ['drag-combo', 'drag-node', 'drag-canvas', 'click-select'];

	if (allowLinkCreation) {
		defaultValues.push({
			type: 'create-edge',
			key: 'shift',
		});
	}

	return defaultValues;
};

const createGraph = (allowLinkCreation: boolean, container: string | HTMLElement, width: number, layout?: LayoutConfig) => {

	return new G6.Graph({
		renderer: 'svg',
		container: 'container',
		width: width,
		height: 600,
		fitView: true,
		fitViewPadding: 50,
		plugins: [toolbar, minimap],
		enabledStack: true,
		defaultCombo: {
			type: 'cRect',
			size: [40, 5],
			style: {
				radius: 6,
				fontSize: 12,
				stroke: 'black',
			},
		},
		modes: {
			default: getDefaultValues(allowLinkCreation),
		},
		defaultNode: {
			type: 'rect',
			size: [120, 45],
			style: {
				radius: 6,
				fontSize: 12,
			},
		},
		defaultEdge: {
			type: 'line-dash',
			style: {
				lineWidth: 2,
				stroke: '#bae7ff',
			},
		},
		nodeStateStyles: {
			selected: {
				lineWidth: 2,
			},
		},
		comboStateStyles: {
			selected: {
				lineWidth: 2,
			},
		},
		layout,
	});

};

const configMap = ( allowLinkCreation: boolean, container: string | HTMLElement, useForceLayout: boolean, width: number) => {

	const layout = configMapLayout(useForceLayout);

	return createGraph(allowLinkCreation, container, width, layout);

};

const UseConfigMap = (allowLinkCreation: boolean, configMapActions: (graph: Graph) => void, container: string | HTMLElement, setExternalGraph: (graph: Graph) => void, useForceLayout: boolean, editorContainerRef: React.RefObject<HTMLDivElement>, mapInfo?: Map): UseConfigMapReturn => {
	const graphRef = useRef<unknown>(null);
	const [currentGraph, setCurrentGraph] = useState(graphRef.current as Graph);

	useEffect(() => {
		const setGraphObj = () => {
			if (graphRef.current != null) {
				return;
			}

			const graph = configMap(allowLinkCreation, container, useForceLayout, editorContainerRef.current?.offsetWidth || 0);

			configMapActions(graph);

			graphRef.current = graph;
			setCurrentGraph(graphRef.current as Graph);
			setExternalGraph(graph);
			graph.clear();
			graph.data({
				id: 'root',
				combos: [],
				edges: mapInfo?.links ?? [],
				nodes: mapInfo?.nodes.map(node => ({
					...node,
					color: node.color,
					style: {
						fill: MapColorsAnnotator.get(node.color),
					},
					labelCfg: {
						style: {
							fill: node.color,
							fontSize: (100 / node.label.length) + 5,
						},
					},
				})) ?? [],
			});
			graph.render();
		};

		setGraphObj();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapInfo]);

	return {
		currentGraph,
	};
};

export default UseConfigMap;
